<template>
  <div class="wordLib">
    <a-button class="gmb-10" @click="$router.back()">返回</a-button>
    <div class="block gmb-10 g-flex g-justify-space-between">
      <div>
        <div class="gfs-18 gfw-b" style="color:#1890ff">
          {{paperDetail.name}}
        </div>
        <div class="gc-888"> {{paperDetail.classification_name}}</div>
      </div>
      <div class="g-flex gtc">
        <div class="gmr-40">
          <div>抽查词库</div>
          <div class="gfs-20">{{paperDetail.word_count }}</div>
        </div>
        <div class="gmr-40">
          <div>攻坚剩余</div>
          <div class="gfs-20">{{paperDetail.word_count - paperDetail.master_count -paperDetail.sweep_count}}</div>
        </div>
        <div class="gmr-40">
          <div>复习掌握</div>
          <div class="gfs-20">{{paperDetail.master_count }}</div>
        </div>
        <div class="gmr-40">
          <div>扫荡生词</div>
          <div class="gfs-20">{{paperDetail.sweep_count }}</div>
        </div>
      </div>
    </div>
    <div class="g-flex" v-if="wordModuleList.length>0">
      <div style="width:200px;" class="gmr-10 gb-fff gpv-10 gph-10">
        <div class="gh-40p g-flex g-flex-c g-pointer gbr-4" :class="{sortActive:param.module_index==index}" v-for="(item,index) in wordModuleList" :key="index" @click="moduleCLick(index)">{{item.module_name}}</div>
      </div>
      <div class="g-flex-1">
        <div class="block gmb-10 g-flex g-justify-space-between g-align-center">
          <div class="g-flex">
            <div class="numItem" :class="{active:wordModuleList[param.module_index].curType==1}" @click="wordModuleList[param.module_index].curType=1">
              <div>抽查词库</div>
              <div>{{wordModuleList[param.module_index].word_count }}</div>
            </div>
            <div class="numItem" :class="{active:wordModuleList[param.module_index].curType==2}" @click="wordModuleList[param.module_index].curType=2">
              <div>攻坚剩余</div>
              <div>{{wordModuleList[param.module_index].word_count - wordModuleList[param.module_index].master_count -wordModuleList[param.module_index].sweep_count}}</div>
            </div>
            <div class="numItem" :class="{active:wordModuleList[param.module_index].curType==3}" @click="wordModuleList[param.module_index].curType=3">
              <div>复习掌握</div>
              <div>{{wordModuleList[param.module_index].master_count }}</div>
            </div>
            <div class="numItem" :class="{active:wordModuleList[param.module_index].curType==4}" @click="wordModuleList[param.module_index].curType=4">
              <div>扫荡生词</div>
              <div>{{wordModuleList[param.module_index].sweep_count }}</div>
            </div>
          </div>
          <div>
            <a-button type="primary" class="gmr-10" @click="toStudy">听写练习</a-button>
            <a-button type="danger" @click="reset">重置</a-button>
          </div>
        </div>
        <div class="block tableBox">
          <div class="boxSearch">
            <div>
              <a-form layout="inline">
                <a-form-item label="">
                  <a-checkbox v-model="param.is_collect" @change="changeTable({current:1})">
                    仅看我的收藏
                  </a-checkbox>
                </a-form-item>
                <a-form-item label="首字母：">
                  <a-select placeholder="请选择首字母" v-model="param.first_letter" style="display:inline-block;width:180px;" @change="changeTable({current:1})">
                    <a-select-option value="">全部</a-select-option>
                    <a-select-option v-for="(item,index) in quesTypeList" :key="index" :value="item">{{item}}</a-select-option>
                  </a-select>
                </a-form-item>
                <a-form-item label="词法：">
                  <a-select placeholder="请选择词法" v-model="param.word_grammar_id" style="display:inline-block;width:180px;" @change="changeTable({current:1})">
                    <a-select-option value="">全部</a-select-option>
                    <a-select-option v-for="(item,index) in wordWayList" :key="index" :value="item.id">{{item.name}}</a-select-option>
                  </a-select>
                </a-form-item>
                <a-form-item label="词类：">
                  <a-select placeholder="请选择词类" v-model="param.word_classify_id" style="display:inline-block;width:180px;" @change="changeTable({current:1})">
                    <a-select-option value="">全部</a-select-option>
                    <a-select-option v-for="(item,index) in wordSortList" :key="index" :value="item.id">{{item.name}}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-form>
            </div>
            <div style="display: flex;align-items: center;">
              <div style="display: flex;align-items: center;justify-content: space-between;margin-right: 10px;">
                <div>
                  <a-input-search placeholder="请输入搜索单词" style="width:320px;" v-model="param.name" @input="changeTable({current:1})" />
                </div>
              </div>
            </div>
          </div>
          <a-table :columns="column" bordered @change="changeTable" :rowKey="item=>item.id" :loading="isLoding" :pagination="{total: total, pageSize: param.limit,current:param.page}" :dataSource="list" style="margin-top: 15px;">
            <span slot="name" slot-scope="t,r">
              <a @click="$router.push({path:'/words/detail',query:{id:r.id}})">{{t}}</a>
            </span>
            <div slot="word_explain" slot-scope="t">
              <a-tooltip>
                <template slot="title">
                  {{t}}
                </template>
                <div class="view-text">{{t}}</div>
              </a-tooltip>
            </div>
            <span slot="is_collect" slot-scope="t,r">
              <a-switch @change="changeStatus(r)" v-model="r.is_collect" />
            </span>
          </a-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  getWordInfoPage,
  changeCollect,
  getWordSortList,
  getWordWayList,
  getWordPaperDetail,
} from "@/api/wordLib";
import { resetModule } from "@/api/word";
import { beforeRouteLeave } from "@/utils/common";

export default {
  data() {
    return {
      paperDetail: {},
      wordWayList: [],
      wordSortList: [],
      wordModuleList: [],
      param: {
        module_index: 0,
        word_paper_id: "",
        word_classify_id: "",
        word_grammar_id: "",
        name: "",
        is_collect: false,
        first_letter: "",
        page: 1,
        limit: 10,
      },
      quesTypeList: [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
      ],
      list: [],
      total: 0,
      column: [
        {
          title: "名称",
          dataIndex: "name",
          scopedSlots: { customRender: "name" },
        },
        {
          title: "词法",
          dataIndex: "word_grammar_name",
          customRender: (res) => (res ? res : "--"),
        },
        {
          title: "词类",
          dataIndex: "word_classify_name",
          customRender: (res) => (res ? res : "--"),
        },
        {
          title: "词义",
          dataIndex: "word_explain",
          width: 300,
          scopedSlots: { customRender: "word_explain" },
        },
        {
          title: "收藏",
          dataIndex: "is_collect",
          scopedSlots: { customRender: "is_collect" },
        },
      ],
      isLoding: false,
    };
  },

  created() {
    this.param.word_paper_id = this.$route.query.id;
    this.getWordPaperDetail(this.param.word_paper_id);
  },
  methods: {
    //词类
    getWordSortList(campus_id) {
      getWordSortList({
        wordPaperId: this.param.word_paper_id,
        campus_id,
      }).then((res) => {
        if (res.code == 200) {
          this.wordSortList = this.dealList(res.data);
        }
      });
    },
    //词法
    getWordWayList(campus_id) {
      getWordWayList({ wordPaperId: this.param.word_paper_id, campus_id }).then(
        (res) => {
          if (res.code == 200) {
            this.wordWayList = this.dealList(res.data);
          }
        }
      );
    },
    reset() {
      //重置
      let id = this.wordModuleList[this.param.module_index].id;
      let _this = this;
      this.$confirm({
        title: "提示",
        content: "是否重置该项",
        onOk() {
          resetModule(id).then((data) => {
            if (data.code === 200) {
              _this.$message.success("重置成功");
              _this.getWordPaperDetail(_this.param.word_paper_id);
            }
          });
        },
      });
    },
    //练习
    toStudy() {
      let num = 0;
      let item = this.wordModuleList[this.param.module_index];
      switch (item.curType) {
        case 1:
          num = item.word_count;
          break;
        case 2:
          num = item.word_count - item.master_count - item.sweep_count;
          break;
        case 3:
          num = item.master_count;
          break;
        case 4:
          num = item.sweep_count;
          break;
      }
      if (!num) {
        this.$message.success("恭喜，您已完成此模块学习！");
        return;
      }
      this.$router.push({
        path: "/learn",
        query: {
          id: this.param.word_paper_id,
          module_id: item.id,
          type: item.curType,
          name: item.name,
        },
      });
    },
    moduleCLick(index) {
      if (this.isLoding) return;
      this.param.module_index = index;
      this.param.page = 1;
      this.search();
    },
    //词汇试卷详情
    getWordPaperDetail(id) {
      getWordPaperDetail(id).then((res) => {
        if (res.code == 200) {
          this.paperDetail = res.data;
          this.getWordSortList(res.data.campus_id);
          this.getWordWayList(res.data.campus_id);
          this.wordModuleList = res.data.wordPaperModuleDetails.map((item) => {
            return {
              ...item,
              curType: 1,
            };
          });
          this.search();
        }
      });
    },
    changeStatus(row) {
      //收藏
      let params = {
        id: row.id,
        is_collect: row.is_collect ? 1 : 0,
        word: row.name,
      };
      changeCollect(params).then((data) => {
        if (data.code === 200) {
        }
      });
    },
    changeTable(page) {
      this.param.page = page.current;
      this.search();
    },
    search() {
      let param = {
        ...this.param,
        module_id: this.wordModuleList[this.param.module_index].id,
        is_collect: this.param.is_collect ? 1 : 0,
      };
      this.isLoding = true;
      getWordInfoPage(param).then((data) => {
        this.isLoding = false;
        if (data.code === 200) {
          this.list = data.data.map((item) => {
            return {
              ...item,
              is_collect: item.is_collect ? true : false,
            };
          });
          this.total = data.total;
        }
      });
    },
    dealList(data) {
      //返回词法||词类列表
      let res = [];
      function test(data) {
        for (let i = 0; i < data.length; i++) {
          res.push({
            id: data[i].id,
            name: data[i].name,
          });
          if (data[i].children) {
            test(data[i].children);
          }
        }
      }
      test(data);
      return res;
    },
  },
  beforeRouteLeave: beforeRouteLeave(["wordDetail"]),
};
</script>
<style lang="less" scoped>
.block {
  background: #fff;
  padding: 24px;
}
.view-text {
  display: inline-block;
  white-space: nowrap;
  width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.wordLib {
  .sortActive {
    background: #1890ff;
    color: #fff;
  }
  .numItem {
    width: 100px;
    margin-right: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &.active,
    &:hover {
      color: rgb(24, 144, 255);
      border-right: 1px solid rgb(24, 144, 255);
      border-left: 1px solid rgb(24, 144, 255);
    }
  }
  .tableBox {
    .boxSearch {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}
</style>
